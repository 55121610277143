.Navbar {
  width: 100%;
  height: 100%;
  .dark-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
  }
  .dark-bg.active {
    opacity: 1;
    visibility: visible;
  }
  .left-bar {
    width: 100px;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    .logo {
      width: 70px;
      height: 100px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .social-network {
      width: 70px;
      height: 300px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .link {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          height: 20px;
        }
      }
      .line {
        width: 2px;
        height: 100px;
        margin: 30px 0 65px 0;
        background-color: rgb(170, 170, 170);
      }
      .text {
        width: 105px;
        margin: 0;
        color: #2b281d;
        letter-spacing: 0.5px;
        font-family: "Staatliches";
        font-size: 18px;
        transform: rotate(-90deg);

        position: relative;
        .text-color {
          margin-right: 5px;
          color: #dc1c00;
          font-size: 25px;

          position: absolute;
          top: -2.5px;
          left: -20px;
        }
      }
    }
    .arrow {
      width: 20px;
      height: 20px;
      margin: auto;
      border: 2px solid #2b281d;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      transition: 0.4s ease-in-out;
      transition-timing-function: cubic-bezier(.9,0,.5,.36);
      cursor: pointer;
      svg {
        margin-top: 1px;
        width: 10px;
      }
    }
  }
  .top-bar {
    width: 100%;
    height: 100px;
    background-color: #2d96ff;
    border-bottom: 2px dashed white;
    display: flex;
    .name {
      width: 150px;
      height: 100px;
      margin-left: 100px;
      border-right: 2px dashed white;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        margin: auto;
        padding: 5px 10px 5px 10px;
        color: #2d96ff;
        background-color: white;
        letter-spacing: 0.5px;
        font-family: "Staatliches";
        font-size: 18px;
      }
    }
    .text-container {
      width: 400px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        margin: 0;
        padding: 0;
        color: white;
        text-align: left;
        line-height: 1.5em;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px;
        position: relative;
        .dot {
          width: 4px;
          height: 4px;
          background-color: #dc1c00;
          position: absolute;
          bottom: 7px;
          right: -6px;
        }
      }
    }
  }
  .corner {
    width: 130px;
    height: 130px;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    &:hover .corner-1 {
      width: 125px;
      height: 125px;
    }
    .corner-1 {
      width: 120px;
      height: 120px;
      fill: white;
      transition: 0.2s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 50;
    }
    .corner-2 {
      width: 130px;
      height: 130px;
      fill: #2740a0;
      transition: 0.2s ease-in-out;
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 5;
    }
    .menu {
      width: 100px;
      height: 100px;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 100;
      .stroke {
        width: 50px;
        height: 2px;
        min-height: 2px;
        margin: auto;
        background-color: #2b281d;
        transition: 0.2s ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 25px;
        z-index: 50;
        &:nth-child(1) {
          top: -30px;
        }
        &:nth-child(2) {
          width: 30px;
        }
        &:nth-child(3) {
          width: 35px;
          top: 30px;
        }
      }
    }
    .menu.active {
      .stroke {
        transform: rotate(45deg);
        &:nth-child(1) {
          width: 2px;
          height: 50px;
          top: 0;
          right: 50px;
        }
        &:nth-child(2) {
          opacity: 0;
          visibility: hidden;
        }
        &:nth-child(3) {
          width: 50px;
          height: 2px;
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Navbar {
    .left-bar {
      width: 80px;
      .logo {
        width: 60px;
        height: 80px;
      }
    }
    .top-bar {
      height: 80px;
      .name {
        width: 150px;
        height: 80px;
        margin-left: 80px;
        .text { font-size: 16px; } // 18
      }
      .text-container {
        width: 340px; // 350
        height: 80px;
        .text { font-size: 14px; }
      }
    }
    .corner {
      width: 120px;
      height: 120px;
      &:hover .corner-1 {
        width: 115px;
        height: 115px;
      }
      .corner-1 {
        width: 110px;
        height: 110px;
      }
      .corner-2 {
        width: 120px;
        height: 120px;
        fill: #2953b5;
      }
      .menu {
        width: 80px;
        height: 80px;
        .stroke {
          width: 40px;
          right: 20px; // 25
          &:nth-child(2) { width: 25px; }
          &:nth-child(3) { width: 30px; }
        }
      }
      .menu.active {
        .stroke {
          &:nth-child(1) {
            height: 40px;
            right: 40px;
          }
          &:nth-child(3) { width: 40px; }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .Navbar {
    .left-bar {
      width: 100px;
      height: 82px;
      position: absolute;
      .logo {
        width: 60px;
        height: 80px;
      }
      .social-network { display: none; }
      .arrow { display: none; }
    }
    .top-bar {
      .name { margin-left: 100px; border: none; }
      .text-container { display: none; }
    }
    .corner {
      width: 120px;
      height: 120px;
      &:hover .corner-1 {
        width: 105px;
        height: 105px;
      }
      .corner-1 {
        width: 100px;
        height: 100px;
      }
      .corner-2 {
        width: 110px;
        height: 110px;
        fill: #2a67cb;
      }
      .menu {
        width: 80px;
        height: 80px;
        .stroke {
          width: 40px;
          right: 20px;
          &:nth-child(2) { width: 25px; }
          &:nth-child(3) { width: 30px; }
        }
      }
      .menu.active {
        .stroke {
          &:nth-child(1) {
            height: 40px;
            right: 40px;
          }
          &:nth-child(3) { width: 40px; }
        }
      }
    }
  }
}

.Homepage {
  width: 100%;
  height: 60vh;
  background: linear-gradient(-45deg, #262b89 10%, #2d96ff 90%);
  position: relative;
  .homepage-container {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -25px;
    z-index: 5;
    h1, h2 {
      margin: 0 auto;
      font-family: "Montserrat";
    }
    h1 {
      margin-bottom: 10px;
      color: white;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.5em;
      letter-spacing: 0.25em;
      font-family: "Staatliches";
      font-size: 50px;
    }
    h2 {
      color: white;
      text-align: center;
      line-height: 1.5em;
      font-weight: 600;
      font-size: 20px;
    }
    .button-container {
      width: 500px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      z-index: 5;
      .button {
        width: 170px;
        margin-bottom: 20px;
        padding: 15px 20px 15px 20px;
        color: white;
        text-align: center;
        text-decoration: none;
        border: 2px solid transparent;
        border-radius: 5em;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px;
        transition: 0.1s;
        outline: none;
        cursor: pointer;
        &:nth-child(1) { 
          background-color: #dc1c00;
          border-color: #dc1c00;
        }
        &:nth-child(2) {
          background-color: transparent; 
          border-color: white;
        }
        &:hover { filter: brightness(1.1); }
      }
    }
  }
  .stars {
    padding: 7px;
    border: 2px solid white;
    border-style: solid none solid none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: 140px;
    z-index: 10;
    span {
      color: white;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 20px;
    }
  }
  .family {
    width: 50%;
    position: absolute;
    bottom: -1px;
    right: 0px;
    z-index: 0;
  }
}

@media (max-width: 1200px) {
  .Homepage {
    .homepage-container {
      width: 90%;
      h1 { font-size: 30px; }
      h2 { font-size: 18px; }
      .button-container {
        width: 400px;
        .button {
          width: 150px;
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .Homepage {
    height: 85vh;
    .homepage-container {
      h1 {
        margin-bottom: 15px;
        font-size: 30px; 
        line-height: 1.25em;
      }
      h2 {
        width: 100%;
        font-size: 16px;
      }
      .button-container {
        width: 100%;
        margin-top: 30px;
        flex-direction: column;
        align-items: center;
      }
    }
    .stars { left: 30px; }
    .family { width: 90%; }
  }
}
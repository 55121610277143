@mixin white-gradient {
	background: linear-gradient(to right,  rgba(245,245,245,1) 0%,rgba(245,245,245,0) 100%);
}

$animationSpeed: 30s;

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-263px * 7))}
}

.Partners {
  padding: 50px 0 50px 0;
  .slider {
    width: 100%;
    margin: auto;
    background: whitesmoke;
    overflow:hidden;
    position: relative;
    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 100px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }
    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }
    &::before {
      left: 0;
      top: 0;
    }
    .slide-track {
      width: calc(250px * 14);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: scroll $animationSpeed linear infinite;
    }
    .partner { width: 80px; }
  }
}
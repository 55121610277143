.Insurance {
  padding: 100px 0 100px 0;
  h2 {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    color: #2b281d;
    letter-spacing: 0.1em;
    font-family: "Staatliches";
    font-size: 30px;
    position: relative;
    &::after {
      content: "";
      width: 25px;
      height: 3px;
      margin: auto;
      background-color: #dc1c00;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
    }
  }
  .insurance-container {
    width: 1200px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    row-gap: 50px;
    .product {
      width: 100%;
      height: 100%;
      border-right: 2px solid rgb(230, 230, 230);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(6) { border: none; }
      &:nth-child(10) { border: none; }
      .icon {
        width: 50px;
        height: 50px;
      }
      .name {
        width: 80%;
        height: 40px;
        margin: 0;
        margin-top: 25px;
        color: #2b281d;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.5em;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .button {
        padding: 5px 10px 5px 10px;
        color: #262b89;
        text-align: center;
        text-decoration: none;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        &:hover { color: #dc1c00; }
        .arrow {
          margin-right: 7px;
          color: #dc1c00;
          font-weight: 700;
        }
      }
    }
  }


  .dark-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .dark-bg.active { visibility: visible; }

  .modal {
    width: 50%;
    height: 100px;
    margin: auto;
    padding: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 110;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .close {
      color: #2b281d;
      font-family: "Montserrat";
      font-size: 25px;
      font-weight: 600;
      position: absolute;
      top: 50px;
      right: 50px;
      cursor: pointer;
      &:hover { color: #dc1c00; }
    }
    img { height: 100px }
    .modal-content {
      width: 100%;
      height: 100px;
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
    .modal-name {
      margin: 0;
      margin-bottom: 15px;
      padding: 0;
      color: #dc1c00;
      letter-spacing: 0.1em;
      font-family: "Staatliches";
      font-size: 25px;
    }
    .link-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      a {
        margin: 0;
        padding: 5px;
        margin-left: 30px;
        margin-right: 50px;
        color: #2d96ff;
        text-decoration: none;
        text-transform: uppercase;
        line-height: 1.5em;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 16px;
        position: relative;
        &::after {
          content: "";
          width: 15px;
          height: 2px;
          margin: auto;
          background-color: #dc1c00;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -30px;
        }
      }
    }
  }
  .modal.active { visibility: visible; }  
}


@media (max-width: 1600px) {
  .Insurance {
    h2 { width: 70%; }
    .insurance-container { width: 75%; }
    .modal {
      width: 70%;
    }
  }
}

@media (max-width: 1400px) {
  .Insurance {
    h2 {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      &::after { display: none; }
    }
    .insurance-container {
      width: 80%; // modifié
      margin: 0 auto;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%; // modifié
      
      .product {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(4) { border: none; }
        &:nth-child(6) { border-right: 2px solid rgb(230, 230, 230); }
        &:nth-child(8) { border: none; }
        &:nth-child(12) { border: none; }
        
        .icon {
          width: 50px;
          height: 50px;
        }
        .name {
          width: 80%;
          height: 40px;
          margin: 0;
          margin-top: 25px;
          color: #2b281d;
          text-align: center;
          text-transform: uppercase;
          line-height: 1.5em;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .button {
          padding: 5px 10px 5px 10px;
          color: #262b89;
          text-align: center;
          text-decoration: none;
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .Insurance {
    padding-top: 50px;
    h2 {
      width: 90%;
      &::after {
        content: "";
        width: 25px;
        height: 3px;
        margin: auto;
        background-color: #dc1c00;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -50px;
      }
    }
    .insurance-container {
      width: 90%; // modifié
      grid-template-columns: 50% 50%; // modifié
      row-gap: 0px;
      .product {
        width: 100%;
        height: 70%;
        align-items: center;
        &:nth-child(2) { border: none; }
        &:nth-child(6) { border: none; }
        &:nth-child(10) { border: none; }
        &:nth-child(12) { border: none; }
        .icon {
          width: 40px;
          height: 40px;
        }
        .name { margin-top: 5px; }
      }
    }
    .modal {
      width: 90%;
      height: 250px;
      padding: 25px 0 25px 0;

      .container {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      .close {
        top: 25px;
        right: 25px;
      }
      img { height: 70px; margin-left: 25px; }
      .modal-content {
        width: 100%;
        height: 100px;
        margin: 0;
        margin-top: 40px;
      }
      .modal-name { margin-left: 25px; font-size: 20px; }
      .link-container {
        margin-left: 25px;
        a { font-size: 14px; }
      }
    }
  }
}
.Company {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid rgb(230, 230, 230);
  h2 {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    color: #2b281d;
    letter-spacing: 0.1em;
    font-family: "Staatliches";
    font-size: 30px;
    position: relative;
    &::after {
      content: "";
      width: 25px;
      height: 3px;
      margin: auto;
      background-color: #dc1c00;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
    }
  }
  h3 {
    margin: 0 auto;
    margin-top: 100px;
    color: #2b281d;
    letter-spacing: 0.1em;
    font-family: "Staatliches";
    font-size: 25px;
    text-align: center;
  }
  .company-container {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      width: 60%;
      min-width: 60%;
      max-width: 60%;
      margin: 0;
      text-align: justify;
      line-height: 1.5em;
      font-family: "Montserrat";
      font-size: 16px;
      .text-color { color: #dc1c00; font-weight: 500; }
    }
    .key-figures-container {
      width: 402px;
      min-width: 402px;
      max-width: 402px;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 50% 50%;
      row-gap: 50px;
      .key-figures {
        width: 100%;
        height: 100px;
        border: 2px solid rgb(230, 230, 230);
        border-style: none solid none none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(2) { border-style: none; }
        &:nth-child(4) { border-style: none; }

        .value {
          color: #2d96ff;
          text-align: center;
          font-family: "Staatliches";
          font-size: 25px;
        }
        .text {
          margin: 0;
          margin-top: 5px;
          color: #2b281d;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .Company {
    h2 { width: 70%; }
    .company-container {
      width: 70%;
      .text {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
        line-height: 1.5em;
      }
    }
  }
}

@media (max-width: 1400px) {
  .Company {
    h2 {
      width: 90%;
      text-align: center;
      &::after { display: none; }
    }
    .company-container {
      width: 90%;
      flex-direction: column;
      .text {
        width: 70%;
        min-width: 70%;
        max-width: 70%;
        text-align: center;
        line-height: 1.5em;
        font-family: "Montserrat";
        font-size: 16px;
        .text-color { color: #dc1c00; font-weight: 500; }
      }
      .key-figures-container {
        margin-top: 50px;
        .key-figures {
          width: 100%;
          height: 100px;
          border: 2px solid rgb(230, 230, 230);
          border-style: none solid none none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:nth-child(2) { border-style: none; }
          &:nth-child(4) { border-style: none; }
  
          .value {
            color: #2d96ff;
            text-align: center;
            font-family: "Staatliches";
            font-size: 25px;
          }
          .text {
            margin: 0;
            margin-top: 5px;
            color: #2b281d;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .Company {
    padding-top: 0;
    h2 {
      width: 90%;
      text-align: center;
      &::after { display: none; }
    }
    .company-container {
      width: 90%;
      flex-direction: column;
      .text {
        width: 90%;
        min-width: 90%;
        max-width: 90%;
        font-size: 14px;
      }
      .key-figures-container {
        width: 90%;
        min-width: 90%;
        max-width: 90%;
        
        .key-figures {
          .value {
            color: #2d96ff;
            text-align: center;
            font-family: "Staatliches";
            font-size: 25px;
          }
          .text {
            width: 80%;
            min-width: 80%;
            max-width: 80%;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.Footer {
  width: 100%;
  height: 370px;
  background-color: #262b89;
  border-top: 2px dashed white;
  .contact-container {
    width: 100px;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 5;
    .link {
      position: relative;
      &:hover { filter: brightness(1.1); }

      &:hover {
        .text {
          visibility: visible;
          opacity: 1;
        }
      }
      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
        padding: 2px;
        background-color: white;
        border-radius: 100%;
        cursor: pointer;
      }
      .text {
        width: 150px;
        height: 30px;
        margin: auto;
        padding: 2.5px 10px 2.5px 10px;
        color: white;
        border-radius: 5em;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -20px;
        bottom: 0;
        left: -180px;
      }
      &:nth-child(1) .text { width: 120px; background-color: #16b58d; left: -150px; }
      &:nth-child(2) .text { background-color: #2d96ff; }
    }
  }
  .footer-container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-container {
      display: flex;
      align-items: center;
      .logo { width: 50px; }
      .name {
        margin-left: 15px;
        padding: 2.5px 5px 2.5px 5px;
        color: #262b89;
        background-color: white;
        font-family: "Staatliches";
        font-size: 16px;
      }
    }
    .address {
      width: 250px;
      margin: 10px 0 10px 0;
      padding: 10px 0 10px 0;
      color: white;
      line-height: 24px;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      a { color: white; text-decoration: none; }
      .title {
        margin-bottom: 5px;
        display: block;
        font-weight: 500;
        position: relative;
        &::after {
          content: "";
          width: 5px;
          height: 2px;
          background-color: white;
          position: absolute;
          left: -15px;
          top: 10px;
        }
      }
    }
    .informations {
      width: 250px;
      margin-bottom: 10px;
      .contact, .ouverture {
        color: white;
        line-height: 24px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        a {
          color: white;
          text-decoration: none;
        }
        .title {
          margin-bottom: 5px;
          display: block;
          font-weight: 500;
          position: relative;
          &::after {
            content: "";
            width: 5px;
            height: 2px;
            background-color: white;
            position: absolute;
            left: -15px;
            top: 10px;
          }
        }
      }
    }

    .sections {
      width: 250px;
      color: white;
      line-height: 20px;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      .title {
        margin-bottom: 5px;
        display: block;
        font-weight: 500;
        position: relative;
        &::after {
          content: "";
          width: 5px;
          height: 2px;
          background-color: white;
          position: absolute;
          left: -15px;
          top: 10px;
        }
      }
      .category {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .section {
          display: flex;
          flex-direction: column;
          a {
            padding: 2px; 
            color: white; 
            text-decoration: none
          }
        }
        .social-network {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .network {
            width: 20px;
            height: 20px;
            margin: 10px 0 10px 0;
            padding: 7px;
            background-color: transparent;
            border: 2px dashed white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Footer {
    height: auto;
    padding: 50px 0 100px 0;
    .footer-container {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .mr-swiss { width: 40%; }
    .informations { width: 40%; }
    .sections {
      width: 40%;
      margin: 0 auto;
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .Footer {
    height: auto;
    .contact-container { display: none; }

    .footer-container {
      flex-direction: column;
      justify-content: center;
    }
    .logo-container {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .name {
        margin: 10px 0 0 0 !important;
      }
    }
    .mr-swiss { width: 250px; }
    .informations { width: 250px; }
    .sections {
      width: 250px;
      margin: 0 auto;
    }
  }
}
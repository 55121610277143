.NavbarForm {
  width: 50%;
  height: 100%;
  background: linear-gradient(-45deg, #262b89, #2d96ff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .notification-container {
    margin: auto;
    display: flex;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 110;
    .notification {
      width: 70%;
      margin: auto;
      padding: 50px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .close {
        color: #2b281d;
        font-family: "Montserrat";
        font-size: 25px;
        font-weight: 600;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        &:hover { color: #dc1c00; }
      }
      h4 {
        margin: 0;
        padding: 0;
        color: #16b58d;
        letter-spacing: 0.1em;
        font-family: "Staatliches";
        font-size: 25px;
      }
      p {
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 16px;
        line-height: 2em;
      }
    }
  }

  .form-container {
    width: 90%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-container {
      width: 90%;
      h3 {
        margin: 0 auto;
        margin-bottom: 50px;
        color: white;
        letter-spacing: 0.1em;
        font-family: "Staatliches";
        font-size: 30px;
      }
    }
    form {
      width: 90%;
      .container {
        display: flex;
        justify-content: space-between;
        .input-container {
          width: 45%;
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          label {
            margin-left: 10px;
            margin-bottom: 15px;
            color: white;
            text-align: left;
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 400;
            .required { color: #dc1c00; }
          }
          input, select {
            padding: 8px;
            color: rgb(230, 230, 230);
            background-color: rgba(38, 43, 137, 0.1);
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 16px;
            border: 2px solid white;
            border-radius: 0px;
            border-style: none none solid none;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            transition: 0.4s;
            &::-webkit-calendar-picker-indicator { filter: invert(1); }
            &:focus { 
              border-color: #2d96ff; 
              resize: none;
              outline: none;
            }
          }
          select {
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 95%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            option { color: #262b89; }
          }
        }
      }
      .button-container {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 170px;
          padding: 15px 20px 15px 20px;
          color: white;
          background-color: #2d96ff;
          text-align: center;
          text-decoration: none;
          border: 2px solid #2d96ff;
          border-radius: 5em;
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 15px;
          transition: 0.1s;
          outline: none;
          cursor: pointer;
          &:hover { filter: brightness(1.1); }
        }
      }
    }
  }
}



@media screen and (max-width: 1200px) {
  .NavbarForm {
    width: 100%;
    display: none;
    .notification-container {
      .notification {
        width: 60%;
        h4 { font-size: 25px; }
        p { font-size: 16px; }
      }
    }

    .form-container {
      .title-container {
        width: 80%;
        h3 {
          margin: 0 auto;
          margin-bottom: 50px;
          color: white;
          letter-spacing: 0.1em;
          font-family: "Staatliches";
          font-size: 30px;
        }
      }
      form {
        width: 80%;
        .container {
          display: flex;
          justify-content: space-between;
          .input-container {
            width: 45%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            label {
              margin-left: 10px;
              margin-bottom: 15px;
              color: white;
              text-align: left;
              font-family: "Montserrat";
              font-size: 16px;
              font-weight: 400;
              .required { color: #dc1c00; }
            }
            input, select {
              padding: 8px;
              color: rgb(230, 230, 230);
              background-color: rgba(38, 43, 137, 0.1);
              font-family: "Montserrat";
              font-weight: 400;
              font-size: 16px;
              border: 2px solid white;
              border-radius: 0px;
              border-style: none none solid none;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              transition: 0.4s;
              &::-webkit-calendar-picker-indicator { filter: invert(1); }
              &:focus { 
                border-color: #2d96ff; 
                resize: none;
                outline: none;
              }
            }
            select {
              background-repeat: no-repeat;
              background-size: 12px;
              background-position: 95%;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              option { color: #262b89; }
            }
          }
        }
        .button-container {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            width: 170px;
            padding: 15px 20px 15px 20px;
            color: white;
            background-color: #2d96ff;
            text-align: center;
            text-decoration: none;
            border: 2px solid #2d96ff;
            border-radius: 5em;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 15px;
            transition: 0.1s;
            outline: none;
            cursor: pointer;
            &:hover { filter: brightness(1.1); }
          }
        }
      }
    }
  }
}
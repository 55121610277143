.ContactForm {
  width: 100%;
  padding: 150px 0 100px 0;
  background: linear-gradient(-45deg, #262b89 10%, #2d96ff 90%);
  position: relative;

  .dark-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .notification-container {
    margin: auto;
    display: flex;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 110;
    .notification {
      width: 50%;
      margin: auto;
      padding: 50px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .close {
        color: #2b281d;
        font-family: "Montserrat";
        font-size: 25px;
        font-weight: 600;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        &:hover { color: #dc1c00; }
      }
      h4 {
        margin: 0;
        padding: 0;
        color: #16b58d;
        letter-spacing: 0.1em;
        font-family: "Staatliches";
        font-size: 25px;
      }
      p {
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 16px;
        line-height: 2em;
      }
    }
  }

  .butterfly-left {
    width: 100px;
    position: absolute;
    bottom: 100px;
    left: 150px;
    z-index: 0;
  }
  .butterfly-right {
    width: 100px;
    position: absolute;
    top: 100px;
    right: 50px;
    z-index: 0;
  }

  .form-container {
    width: 1200px;
    margin: 0 auto;
  }

  form {
    position: relative;
    z-index: 1;
    .container {
      display: flex;
      justify-content: space-between;
      .input-container {
        width: 45%;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        label {
          margin-bottom: 15px;
          color: white;
          text-align: center;
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 400;
          .required {
            color: #dc1c00;
          }
        }
        input, select {
          padding: 8px;
          color: rgb(230, 230, 230);
          background-color: rgba(38, 43, 137, 0.1);
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 16px;
          border: 2px solid white;
          border-radius: 0px;
          border-style: none none solid none;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          transition: 0.4s;
          &:focus { 
            border-color: #2d96ff; 
            resize: none;
            outline: none;
          }
        }
        select {
          background-repeat: no-repeat;
          background-size: 12px;
          background-position: 95%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          option { color: #262b89; }
        }
      }
      .textarea-container {
        width: 100%;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        label {
          margin-bottom: 15px;
          color: white;
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 400;
        }
        textarea {
          padding: 8px;
          color: rgb(230, 230, 230);
          background-color: rgba(38, 43, 137, 0.1);
          font-weight: 400;
          font-family: "Montserrat";
          font-size: 16px;
          border: 2px solid white;
          border-radius: 0px;
          border-style: none none solid none;
          resize: vertical;
          transition: 0.4s;
          &:focus { 
            border-color: #2d96ff; 
            resize: none;
            outline: none;
          }
        }
      }
    }
    .button-container {
      display: flex;
      justify-items: center;
      align-items: center;
      button {
        width: 170px;
        margin: 0 auto;
        margin-top: 60px;
        padding: 15px 20px 15px 20px;
        color: white;
        background-color: #2d96ff;
        text-align: center;
        text-decoration: none;
        border: 2px solid #2d96ff;
        border-radius: 5em;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 15px;
        transition: 0.1s;
        outline: none;
        cursor: pointer;
        &:hover { filter: brightness(1.1); }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .ContactForm {

    .form-container { width: 70%; }
  }
}

@media screen and (max-width: 1200px) {
  .ContactForm {
    .notification-container {
      .notification {
        width: 60%;
        h4 { font-size: 25px; }
        p { font-size: 16px; }
      }
    }
    .form-container {
      width: 70%;
    }
    form {
      .container {
        flex-direction: column;
        .input-container {
          width: 100%;
        }
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .ContactForm {
    padding-top: 100px;



    .butterfly-left { display: none; }
    .butterfly-right { top: 25px; right: 25px; }
    .form-container {
      width: 90%;
    }
    form {
      .button-container {
        button { padding: 10px; }
      }
    }
  }
}
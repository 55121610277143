.Nav {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  clip-path: circle(0px at 100% 0%);
  -webkit-clip-path: circle(0px at 100% 0%);
  transition: 0.4s ease-in-out;
  transition-timing-function: cubic-bezier(.9,0,.5,.36);
  visibility: hidden;
  // .light-bg {
  //   width: 100%;
  //   height: 100%;
  //   background-color: white;
  //   opacity: 1;
  //   visibility: visible;
  //   transition: 0.8s;
  //   transition-delay: 0.4s;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 50;
  // }
  // .light-bg.active {
  //   opacity: 0;
  //   visibility: hidden;
  // }

  .nav-items {
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    nav {
      width: 90%;
      height: 600px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item {
        width: 80%;
        margin: 0;
        padding: 50px 0 50px 50px;
        color: #2b281d;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 1px solid rgb(200, 200, 200);
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 16px;
        position: relative;
        &:nth-child(4) {
          border: none;
        }
      }


      .contact-container {
        width: 70%;
        margin-left: 0px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .contact {
          display: flex;
          flex-direction: column;
          a {
            margin: 0;
            padding: 0;
            color: #2b281d;
            text-decoration: none;
            line-height: 1.5em;
            font-family: "Montserrat";
            font-size: 14px;
            position: relative;
            .text-weigth { color: #2d96ff; font-weight: 500; }
            &::after {
              content: "";
              width: 5px;
              height: 2px;
              margin: auto;
              background-color: #2d96ff;
              position: absolute;
              top: 0;
              bottom: 0;
              left: -15px;
            }
          }
        }
        .social-network {
          width: 50px;
          padding: 5px;
          display: flex;
          justify-content: space-between;
          a {
            width: 20px;
            height: 20px;
            margin: 0 10px 0 10px;
            padding: 7px;
            background-color: transparent;
            border: 2px dashed #2b281d;
            cursor: pointer;
            .network {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.Nav.active { 
  clip-path: circle(150% at 100% 0%); 
  -webkit-clip-path: circle(150% at 100% 0%);
  visibility: visible;

}

@media screen and (max-width: 1200px) {
  .Nav {
    .nav-items {
      width: 100%;
      display: flex;
    }
  }
}


@media screen and (max-width: 600px) {
  .Nav {
    .nav-items {
      .contact-container {
        width: 100% !important;
        flex-direction: column;
        .social-network {
          a {
            margin: -20px !important;
            margin-top: 20px !important;
          }
        }
      }
    }
  }
}